@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}

.navbar .nav-right {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: #949494;
}

.content {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
}

/* blog previews / list */
.offer-card {
  margin: 0;
  border: 0.5px solid #c6c6c6;
  border-radius: 5px;
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
}

.offer-address {
  color: #949494;
  font-weight: normal;
}

.offer-address path {
  color: #949494;
}

.offer-infos {
  color: #c6c6c6;
  font-weight: normal;
}

.offer-infos path {
  color: #c6c6c6;
}

.offer-price {
  color: #E13737;
  font-weight: bolder;
}

.offer-price .charges {
  font-weight: normal;
  color: #949494;
  font-size: x-large;
}

.offer-details {
  padding: 10px 10px 10px 16px;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.offer-card:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}

.offer-card h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 8px;
}

.offer-card a {
  text-decoration: none;
}

.blog-details div {
  margin: 20px 0;
}

.button {
  background: #E13737;
  color: #fff;
  border: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: large;
  font-weight: bold;
}

.button:disabled {
  background: #eaeaea;
  color: #fff;
  border: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  cursor: not-allowed;
  text-decoration: none;
  display: inline-block;
  font-size: large;
  font-weight: bold;
}

.button path,
.button-disabled path {
  color: #fff;
}

.logo {
  max-width: 128px;
}

.title-second {
  font-weight: lighter;
  color: #949494;
  font-size: 32px;
}

.title {
  font-weight: normal;
  font-size: 48px;

}

.home {
  padding-left: 20px;
  padding-right: 20px;
}


.picture-right {
  width: 500px;
  float: right;
}

.offers {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three equal-width columns */
  gap: 25px;
  /* Adjust the gap between columns as needed */
}

hr {
  border: 0.5px solid #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 0.5px solid #c6c6c6;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #949494;
  resize: vertical;
}

input[type=text],
select {
  height: 40px;
}

.input::placeholder {
  color: #c6c6c6;
}

.search-form {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three equal-width columns */
  gap: 10px;
  /* Adjust the gap between columns as needed */
}

form label {
  font-weight: bold;
  color: #949494;
}

.offer-picture {
  max-width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.offer p {
  margin-top: 15px;
  line-height: 1.5;
}

.login-form,
.register-form {
  margin-top: 20px;
}

.form-title {
  text-align: center;
  font-weight: lighter;
  color: #949494;
}

.fields-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three equal-width columns */
  gap: 10px;
  /* Adjust the gap between columns as needed */
}

.form-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.offer-detail-picture {
  width: 100%;
  border-radius: 5px;
}

.contact-info {
  border: 0.5px solid #c6c6c6;
  border-radius: 5px;
  padding: 20px;
}

.contact-info h3,
.contact-info h3 path {
  color: #949494;
  font-weight: normal;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-8 {
  margin-right: 8px;
}

.hidden {
  display: none;
}

.offer-text {
  text-align: justify;
}

.offer-card .offer-price {
  font-size: x-large;
}

.offer-card .offer-price .charges {
  font-size: medium;
}

.banner {
  border: 1px solid red;
  background-color: lightcoral;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.banner-success {
  border: 1px solid green;
  background-color: lightgreen;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.input-error {
  border: 0.5px solid #E13737;
  color: #E13737;
}

/* Style pour la liste des cases à cocher */
.services-list,
.offer-services {
  list-style: none;
  /* Supprime les puces de la liste */
  padding: 0;
  /* Supprime la marge intérieure */
  display: flex;
  /* Utilise la disposition flexible pour aligner les éléments sur la même ligne */
}

.services-list label {
  color: #949494;
  font-weight: normal;
}

/* Style pour chaque élément de la liste (case à cocher + label) */
.services-list li {
  margin-right: 20px;
  /* Espacement entre chaque élément */
  display: flex;
  /* Utilise la disposition flexible pour aligner les éléments sur la même ligne */
  align-items: center;
  /* Centre verticalement les éléments */
}

/* Style pour les cases à cocher */
.services-list li input[type="checkbox"] {
  margin-right: 5px;
  /* Espacement entre la case à cocher et le label */
}

.delete-account {
  border: 0;
  background-color: transparent;
  color: #949494;
  float: right;
}

.delete-account:hover {
  cursor: pointer;
  color: red;
}

.offer-services path {
  color: #c6c6c6;
  font-weight: normal;
}

/* Style pour chaque élément de la liste (case à cocher + label) */
.offer-services li {
  margin-right: 15px;
  /* Espacement entre chaque élément */
  display: flex;
  /* Utilise la disposition flexible pour aligner les éléments sur la même ligne */
  align-items: center;
  /* Centre verticalement les éléments */
  color: #c6c6c6;

}

.offer-services li svg {
  margin-right: 5px;
}

.loading {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  display: flex;
}

.offer-message {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: x-large;
  font-weight: bold;
  color: #c6c6c6;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-10 {
  flex: 10%;
}

.flex-20 {
  flex: 20%;
}

.flex-30 {
  flex: 30%;
}

.flex-40 {
  flex: 40%;
}

.flex-50 {
  flex: 50%;
}

.flex-60 {
  flex: 60%;
}

.flex-70 {
  flex: 70%;
}

.flex-80 {
  flex: 80%;
}

.flex-90 {
  flex: 90%;
}

.text-right {
  text-align: right;
}

.property-details {
  list-style: none;
  padding: 0;
  line-height: 2;
}

.property-details label {
  margin-left: 8px;
  color: #000;
  font-weight: normal;
}

input[type=file] {
  border: 0.5px solid #c6c6c6;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 8px;
}

.edit-actions button {
  border: 0.5px solid #c6c6c6;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  align-items: bottom;
}

.edit-actions {
  align-self: flex-end;
  margin-top: 16px;
}

.edit-actions button:hover {
  background-color: #c6c6c6;
  cursor: pointer;
}